<template>
  <div class="page-root tools-pinggu-root">
    <div class="tools-banner-wrapper">
      <div class="tools-banner">
        <div class="text">
          <div class="zh">二手车在线快速评估</div>
          <div class="en">QUICK ONLINE EVALUATION OF USED CARS</div>
          <div class="small">基于海量二手车交易数据提供的车辆估值服务，为您的二手车交易提供价格指导</div>
          <div class="line"></div>
        </div>
        <div class="box"></div>
        <img src="@/assets/matt-chambers-m6NCjmFtuI4-unsplash@2x.png" />
        <div class="hidden-md-and-up" style="color: #fff; margin-top: 40px; font-size: 13px" @click="search('sim')">
          查看报告样例 >
        </div>
      </div>
    </div>
    <div class="tabs-wrapper">
      <div class="title">快速评估</div>
      <div class="list-bar">
        <div class="list">
<!--          <el-cascader-->
<!--            class="hidden-md-and-down"-->
<!--            clearable-->
<!--            :props="props"-->
<!--            v-model="value"-->
<!--            placeholder="品牌/车系/车型"-->
<!--            :append-to-body="false"-->
<!--          ></el-cascader>-->
          <cyt-car-select class="hidden-md-and-down" @carSelected="handleCarSelected"/>
          <van-field
            class="hidden-md-and-up"
            v-model="fieldValue"
            is-link
            readonly
            placeholder="品牌/车系/车型"
            @click="show = true"
          />
          <van-popup v-model="show" round position="bottom">
            <van-cascader
              v-model="cascaderValue"
              title="品牌/车系/车型"
              :options="options"
              @close="show = false"
              @change="onChange"
              @finish="onFinish"
            />
          </van-popup>
          <el-date-picker
            :editable="false"
            :picker-options="pickerOptions"
            v-model="value1"
            value-format="yyyy-MM"
            type="month"
            placeholder="上牌时间"
            @change="monthChange"
            :append-to-body="false"
          ></el-date-picker>
          <el-cascader
            :append-to-body="false"
            ref="cityChange"
            clearable
            :props="props1"
            v-model="value2"
            placeholder="所在城市"
          ></el-cascader>
          <el-input placeholder="行驶里程" type="number" v-model="value3" @keyup.native="number">
            <template slot="append">万公里</template>
          </el-input>
        </div>
        <div class="butt">
          <div @click="search">开始查询</div>
          <div @click="search('sim')">查看报告样例 ></div>
        </div>
        <div class="butt-sm">
          <div @click="search">开始查询</div>
        </div>
      </div>
    </div>
    <div class="cars-wrapper-sm">
      <div class="car-tabs">
        <div class="car-list">
          <img src="./assets/全面覆盖车型.svg" />
          <div>
            <div class="title">完整的车型库</div>
            <div class="data">自建车型数据库每周一更新，包括160多个厂商超过7万的车型数据，提供品牌车型数据查询服务</div>
          </div>
        </div>
        <div class="car-list">
          <img src="./assets/精准定价.svg" />
          <div>
            <div class="title">真实数据汇集</div>
            <div class="data">每年超过千万条的真实车辆价格数据汇集，依托强大的大数据服务，车辆评估结果真实可靠</div>
          </div>
        </div>
        <div class="car-list">
          <img src="./assets/先进估价模型.svg" />
          <div>
            <div class="title">权威第三方服务机构</div>
            <div class="data">独立第三方评估服务平台，结合车辆的具体状况，为您的二手车提供精准的市场定价</div>
          </div>
        </div>
      </div>
    </div>
    <div class="cars-wrapper">
      <div class="car-tabs">
        <div class="car-list">
          <img class="img" src="./assets/全面覆盖车型.svg" />
          <div class="title">完整的车型库</div>
          <div class="data">自建车型数据库每周一更新，包括160多个厂商超过7万的车型数据，提供品牌车型数据查询服务</div>
        </div>
        <div class="car-list">
          <img class="img" src="./assets/精准定价.svg" />
          <div class="title">真实数据汇集</div>
          <div class="data">每年超过千万条的真实车辆价格数据汇集，依托强大的大数据服务，车辆评估结果真实可靠</div>
        </div>
        <div class="car-list">
          <img class="img" src="./assets/先进估价模型.svg" />
          <div class="title">权威第三方服务机构</div>
          <div class="data">独立第三方评估服务平台，结合车辆的具体状况，为您的二手车提供精准的市场定价</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CytCarSelect from "@/components/CytCarSelect/CytCarSelect.vue";
import './styles/mobile.scss';
import './styles/pc/pc.scss';
import { get, post } from '@/utils/axios';
export default {
  components: {
    CytCarSelect
  },
  methods: {
    number() {
      this.value3 = this.value3.replace(/[^\.\d]/g, '');
      // this.value3 = this.value3.replace('.', '');
    },
    monthChange() {
      if (!this.value.length && !this.cascaderValue) {
        this.$message({
          message: '先选择车型才能选择上牌日期 ！',
          type: 'warning',
        });
        this.value1 = '';
      }
    },
    //手机端车型选择
    onChange({ value, selectedOptions, tabIndex }) {
      if (tabIndex === 0) {
        get(`/system/brand/list/s/${value}`).then((res) => {
          this.options[selectedOptions[0].index].children = res.data.map((item, i) => {
            return {
              index: i,
              text: item.vehicleSeriesName,
              value: item.vehicleSeriesId,
              children: [],
            };
          });
        });
      }
      if (tabIndex === 1) {
        get(`/system/brand/list/t/${value}`).then((res) => {
          this.options[selectedOptions[0].index].children[selectedOptions[1].index].children = res.data.map((item) => {
            return {
              text: item.vehicleTypeName,
              value: item.vehicleTypeId,
            };
          });
        });
      }
    },
    onFinish({ selectedOptions }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join('/');
      console.log("onFinish", selectedOptions)
      this.toYearMile(selectedOptions[2].text)
    },
    search(params) {
      if (params == 'sim') {
        this.$router.push({
          name: 'Tools-pgResult',
          query: {
            id: 1
          },
        });
      } else {
        if (!this.value.length && !this.cascaderValue) {
          this.$message({
            message: '车型尚未选择！',
            type: 'warning',
          });
        } else if (!this.value1) {
          this.$message({
            message: '上牌时间尚未选择！',
            type: 'warning',
          });
        } else if (!this.value2.length) {
          this.$message({
            message: '城市尚未选择！',
            type: 'warning',
          });
        } else if (!this.value3) {
          this.$message({
            message: '行驶里程尚未填写！',
            type: 'warning',
          });
        } else {
          this.pageLoading = this.$loading({
            lock: true,
            fullscreen: true,
            text: '正在生成估值报告...',
          })
          post('/web/asse', {
            vehicleTypeId: this.cascaderValue || this.value[2],
            licensingDate: this.value1,
            cityId: this.value2[1],
            drivingKilometres: this.value3,
            city: this.$refs['cityChange'].getCheckedNodes()[0].label,
          }).then((res) => {
            if (res.code == 200) {
              this.pageLoading.close();

              this.$router.push({
                name: 'Tools-pgResult',
                query: {
                  id: res.data.id,
                },
              });
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      }
    },
    handleCarSelected(value) {
      this.cascaderValue = value[2]; // 在carSelected事件中更新cascaderValue
      this.toYearMile(value[3])
    },
    toYearMile(str){
      let year = ''
      let index = str.indexOf('款');
      if (index !== -1) {
        year = str.substring(index - 4, index); 
      }
      if(year){
        let currentYear = new Date().getFullYear(); // 获取当前年份
        let difference = currentYear - year; 
        if(difference>0){
          this.value1 = year+'-06'
          this.value3 = (difference*0.8).toFixed(1)
        }else{
          this.value1 = year+'-01'
          this.value3 = 0.5
        }
      }
    }
  },
  mounted() {
    get('/system/brand/list').then((res) => {
      this.options = res.rows.map((item, i) => {
        return {
          index: i,
          text: item.brandName,
          value: item.brandId,
          children: [],
        };
      });
    });
  },
  data() {
    return {
      show: false,
      fieldValue: '',
      cascaderValue: '',
      options: [],
      value: [],
      value1: '',
      value2: ["110000","110100"],
      value3: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level, value } = node;
          let url = '',
            nodes = [];
          if (level == 2) {
            url = `/system/brand/list/t/${value}`;
          } else if (level == 1) {
            url = `/system/brand/list/s/${value}`;
          } else {
            url = '/system/brand/list';
          }
          get(url).then((res) => {
            if (level == 2) {
              nodes = res.data.map((item) => {
                return {
                  value: item.vehicleTypeId,
                  label: item.vehicleTypeName,
                  leaf: level >= 2,
                };
              });
            } else if (level == 1) {
              nodes = res.data.map((item) => {
                return {
                  value: item.vehicleSeriesId,
                  label: item.vehicleSeriesName,
                  leaf: level >= 2,
                };
              });
            } else {
              nodes = res.rows.map((item) => {
                return {
                  label: item.brandName,
                  value: item.brandId,
                  leaf: level >= 2,
                };
              });
            }
            resolve(nodes);
          });
        },
      },
      props1: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level, value } = node;
          let url = '/system/region/list';
          if (level == 1) {
            url = `/system/region/list?parentId=${value}`;
          }
          get(url).then((res) => {
            let nodes = res.rows.map((item) => {
              return {
                value: item.id,
                label: item.name,
                leaf: level >= 1,
              };
            });
            resolve(nodes);
          });
        },
      },
      pageLoading: null,
    };
  },
};
</script>
